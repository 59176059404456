import { Component, OnInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Customer, TUser, TSupplier, ResetPassword, PhotosDetails} from './Customer';
import { CustomerService } from '../my-profile/Shared/customer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})

export class MyProfileComponent implements OnInit {

  public customer: any;
  public tUser: any;
  public tUserOtherDatail: any;
  public tSupplier: any;
  public photodetails: any;
  dropdownSettings: any;
  TitleDescription: any[];
  TimeZoneDetails: any[];
  DateFormat: any[];
  resetPassword: any;
  public mystring: any;

  constructor(private _customerService: CustomerService, private toastr: ToastrService) { }

  ngOnInit() {
    this.customer = new Customer();
    this.tUser = new TUser();
    this.tSupplier = new TSupplier();
    this.resetPassword = new ResetPassword();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'moduleId',
      textField: 'moduleName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemShowLimit: 1,
      allowSearchFilter: false

    };
    this.getTitleList();
    this.getTimeZoneList();
    this.getDateFormatList();

  }

  public POSTInsertOrUpdateCustomer() {
    debugger
   
    this._customerService.InsertorUpdateCustomer(this.customer)
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            debugger
            this.toastr.success(result.message, "", { timeOut: 5000 });
          }
          else {

            this.toastr.error(result.message, "", { timeOut: 5000 });
          }
        } catch (Error) {
          alert(Error.message);
        }
      })
  }


  //public POSTInsertOrUpdateUser() {
  //  debugger
  //  this._customerService.InsertorUpdateUser(this.customer)
  //    .then((result) => {
  //      try {
  //        if (result.isSuccessful == true) {
  //          debugger
  //        }
  //        else {

  //          // this.toastr.error(" ", this.Messege.AllMesseges.OrganizationInsertorUpdateError);
  //        }
  //      } catch (Error) {
  //        alert(Error.message);
  //      }
  //    })
  //}

  async getTitleList() {
    debugger
    this._customerService.getTitlelist()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.TitleDescription = result.data;
          }
        } catch (Error) {
          // this.toastr.error("", Error.message);
        }
      })
  }

  async getTimeZoneList() {
    debugger
    this._customerService.getTimeZonelist()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.TimeZoneDetails = result.data;
          }
        } catch (Error) {
          // this.toastr.error("", Error.message);
        }
      })
  }


  async getDateFormatList() {
    debugger
    this._customerService.getDateFormatlist()
      .then((result) => {
        try {
          if (result.isSuccessful == true) {
            this.DateFormat = result.data;
          }
        } catch (Error) {
          // this.toastr.error("", Error.message);
        }
      })
  }
  detectFiles(event) {
    debugger
    let files = event.target.files;
    if (files) {
        var mimetype = files[0].type;
        if (mimetype.match(/image\/*/) == null) {
          //this.toastr.error("", this.Messege.AllMesseges.uploadImageError)
          return;
        }
      let file = files[0];
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.mystring=e.target.result;
          let picstring = this.mystring.replace('data:image/jpeg;base64,', ' ');
          this.customer.base64ImageString = picstring;
          this.customer.imageName = files[0].name;
        }
        if (file && file.type.match('image.*')) {
          reader.readAsDataURL(file);
        }

    }
  }


  handleReaderLoaded() {
    debugger
    for (let picstring of this.mystring) {
    
      //  this.PhotosResult.Base64PictureCollection.push(picstring);
     
    }
  }


  readImage(event) {
    debugger
    let mystr: any;
    let files = event.target.files;
    this.customer.imageName = event.target.files[0].name;
    //this.customer.imageExtention = event.target;
    //files.name;
    //this.mystring.push(event.target.result);
    //if (files) {
    //  //for (let i = 0; i < 3; i++) {
    var mimetype = event.target.files[0].type;
       if (mimetype.match(/image\/*/) == null) {
         // this.toastr.error("", this.messege.allmesseges.uploadimageerror)
          return;
        }
    let file = files[0];
    let reader = new FileReader();

    

    reader.onload = readSuccess
    //  (e: any) => {
    //  this.mystring = e.target.result;
    //}
    function readSuccess(evt) {
      mystr = evt.target.result;
      //this.mystring = this.mystring.replace('data:image/jpeg;base64,', ' ');
      //this.customer.base64ImageString = this.mystring;
    };
    if (file && file.type.match('image.*')) {
      reader.readAsDataURL(file);
    }
    this.mystring = mystr.replace('data:image/jpeg;base64,', ' ');
      this.customer.base64ImageString = this.mystring;
      //}

      //}//for loop
    }//files

  }

  //readerImage() {
  //  debugger
  //  this.mystring = this.mystring.replace('data:image/jpeg;base64,', ' ');
  //  this.customer.base64ImageString.push(this.mystring);
    
  //}

//}
