import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { LoginLayoutComponent } from './_layout/login-layout/login-layout.component';
import { FooterComponent } from './_layout/site-layout/footer/footer.component';
import { MenuComponent } from './_layout/site-layout/menu/menu.component';
import { HeaderComponent } from './_layout/site-layout/header/header.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout/site-layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { Global } from './shared/global';


@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    MenuComponent,
    HeaderComponent,
    SiteLayoutComponent,
    LoginComponent,
    DashboardComponent,
    CustomerComponent,
    MyProfileComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    HttpModule,
    ToastNoAnimationModule.forRoot(),
    RouterModule.forRoot([
      {
        path:'',
        component:LoginLayoutComponent,
        children:[
          { path: '', component: LoginComponent, pathMatch: 'full' },
          { path: 'login', component: LoginComponent, pathMatch: 'full' }
         
        ]
      },

      {
        path:'',
        component:SiteLayoutComponent,
        children:[
          { path: 'dashboard', component: DashboardComponent },
          { path: 'customer', component: CustomerComponent },
          { path: 'my-profile', component: MyProfileComponent }
        ]
      },
     
      { path: '**', redirectTo: '',pathMatch:'full' }
     ])
  ],
  providers: [
    Global
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
