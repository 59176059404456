import { Component } from '@angular/core';
import { Global } from './shared/global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(private global: Global, private router:Router){
    debugger;
    global.role = "Shailesh PM";
    if(window.location.href.indexOf("localhost") < 0)
    {
     let strURl : string[];
      strURl = window.location.href.split('.');
      if(strURl.length > 0)
      {
        strURl[0] = strURl[0] + "api";
      }
      global.apiBaseURl = strURl.join('.')+'api/';
      console.log(global.apiBaseURl);
    }
    else
    {
      global.apiBaseURl = "http://localhost:61872/api/";
    }
  };

}
