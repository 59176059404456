import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Global } from 'src/app/shared/global';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private requestUrl = "";
  private _global: Global;

  constructor(private http: Http, private global: Global) {
    this._global = global;
  }
  InsertorUpdateCustomer(customerdetails: any): Promise<any> {
    debugger

    let headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Access-Control-Allow-Headers': 'Content-Type'
    });
    this.requestUrl = this._global.apiBaseURl + 'Customer/InsertCustomer';
    return this.http.post(this.requestUrl, customerdetails, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  //InsertorUpdateUser(customerdetails: any): Promise<any> {
  //  debugger
  //  let headers = new Headers({ 'Content-Type': 'application/json' });
  //  this.requestUrl = 'http://localhost:61872/api/Customer/InsertCustomer';
  //  return this.http.post(this.requestUrl, customerdetails, { headers: headers })
  //    .toPromise()
  //    .then(response => response.json() as any)
  //    .catch(respose => respose.json() as any)
  //}

  public getTitlelist(): Promise<any> {
    debugger
    let headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.href,
      'Access-Control-Allow-Methods': 'GET,POST',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Access-Control-Allow-Credentials': 'true'
      
    });
    
    this.requestUrl = this._global.apiBaseURl + "Customer/GetTitleFromMCfgMasterDetails";
    return this.http.get(this.requestUrl, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public getTimeZonelist(): Promise<any> {
    debugger
    let headers = new Headers({ 
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': this._global.apiBaseURl,
      'Access-Control-Allow-Methods': 'GET,POST',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Access-Control-Allow-Credentials': 'true'

    });
    this.requestUrl = this._global.apiBaseURl + "Customer/GetTimeZoneFromMCfgMasterDetails";
    return this.http.get(this.requestUrl, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

  public getDateFormatlist(): Promise<any> {
    debugger
    let headers = new Headers({ 
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': this._global.apiBaseURl,
      'Access-Control-Allow-Methods': 'GET,POST',
      'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
      'Access-Control-Allow-Credentials': 'true'
    });
    this.requestUrl = this._global.apiBaseURl + "Customer/GetDateFormatFromMCfgMasterDetails";
    return this.http.get(this.requestUrl, { headers: headers })
      .toPromise()
      .then(response => response.json() as any)
      .catch(respose => respose.json() as any)
  }

}
