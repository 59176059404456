import { Data } from "@angular/router";

export class Customer {
        public customerId: number = 1; 
        public customerFirstName: string;
        public customerLastName: string;
        public customerTenantName: string ;
        public customerDisplayName: string ;
        public customerLogoFileName: string = "";
        public customerLogoOriginalFilePath: string ="";
        public customerImageFileName: string ="";
        public customerImageOriginalFilePath: string ="";
        public customerTimeZoneCfgId: number;
        public customerDateFormatCfgId: number;
        public customerStreetName: string;
        public customerState: string;
        public customerCity: string;
        public customerCountry: string;
        public customerZipcode: string;
        public customerIsActive: boolean;
        public customerCreatedDate: Date;
        public customerCreatedBy: number = 1 ;
        public customerUpdatedDate: Date;
        public customerUpdatedBy: number = 1;
        public customerCompanyName: string; 
        public customerDeparmentName: string;
        public customerTitleCfgId: number = 1;
        public customerEmailId: string;
        public customerOfficeNumber: string;
        public customerMobileNumber: string;
        public UserPassword: string;

        public imageName: string;
        public imageExtention: string;
        public base64ImageString: any[];
        public imagePath: any;

}

export class TUser {
        public userId: number;
        public userEntityIdentifier: any;
        public userEntityId: number;
        public userFirstName: string;
        public userMasterLastName: string;
        public userEmailId: string;
        public userPassword: string;
        public userStatusCfgId: number;
        public roleMasterId: number;
        public userMasterIsActive: number;
        public userMasterCreatedDate: Data;
        public userMasterCreatedBy: number;
        public userMasterUpdatedDate: Date;
        public userMasterUpdatedBy: number;
}

export class TSupplier {

  public supplierId: number;
  public supplierTypeCfgId: number;
  public supplierIncotermCfgId: number;
  public supplierName: string;
  public customerId: number;
  public supplierErpVendorId: string;
  public supplierWebSiteUrl: string; 
  public supplierDisplayName: string;
  public supplierPlannerName: string;
  public supplierPlannerEmailId: string;
  public supplierBuyerName: string;
  public supplierBuyerEmailId: string;
  public supplierStreetName: string;
  public supplierState: string;
  public supplierCity: string;
  public supplierCountry: string;
  public supplierZipcode: string;
  public supplierIsActive: boolean;
  public supplierCreatedDate: Date; 
  public supplierCreatedBy: number;
  public supplierUpdatedDate: Date;
  public supplierUpdatedBy: number;
}

export class ResetPassword {
  public newPassword: string;
  public reEnterPassword: string;
  public resteID: number;
}

export class PhotosDetails {

  public imageName: string;
  public imageExtention: string;
  public Base64Image: any[];
}

